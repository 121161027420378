import React, { FunctionComponent } from 'react'
import {SentenceElementProps} from '../types/custom-types'


export const SentenceElement: FunctionComponent<SentenceElementProps> = ({ attributes, children, element }) => {
    return (
        <span
            {...attributes}
            style={{
                marginLeft: '0.5rem',
                borderLeft: '0.05rem solid black'
            }}
        >
        {children}
        </span>
    )
}

export default SentenceElement
