import React, { FunctionComponent } from 'react'
import { WordElementProps } from '../types/custom-types'

const colorOfSyllableComplexity = (value: number | null) => {
    if (value) {
        if (value < 0.1) {
            return 'rgb(200,100,100)'
        } else if (value < 0.2) {
            return 'rgb(255,150,150)'
        } else if (value < 0.3) {
            return 'rgb(255,170,170)'
        } else if (value < 0.4) {
            return 'rgb(230,230,255)'
        } else if (value < 0.6) {
            return 'rgb(200,255,255)'
        } else if (value < 0.8) {
            return 'rgb(200,230, 200 )'
        } else if (value < 1) {
            return 'rgb(200,255,200 )'
        }
    } else {
        return 'rgb(255,255,255)'
    }
}

export const WordElement: FunctionComponent<WordElementProps> = ({ attributes, children, element }) => {
    const syllableComplexity: number = element.syllableComplexity;

    return (
        <span
            {...attributes}
            style={{
                marginLeft: '0.5rem',
                borderLeft: '0.05rem solid black',
                backgroundColor: syllableComplexity ? colorOfSyllableComplexity(syllableComplexity) : 'transparent'
            }}
            title={syllableComplexity}
        >
        {children}
        </span>
    )
}

export default WordElement
