import React from 'react';
import SentenceElement from './SentenceElement'
import ParagraphElement from './ParagraphElement'
import WordElement from './WordElement'
import LoadingElement from './LoadingElement'
import { ElementProps } from '../types/element-props'

export const EditorElement: React.FC<ElementProps> = ({ attributes, children, element }) => {

    switch (element.type) {
        case 'sentence':
            return <SentenceElement {...{ attributes, element }}
                                    flesch={element.flesch}
                                    lastText={element.lastText}
                                    children={children} />;
        case 'paragraph':
            return <ParagraphElement {...{ attributes, element }}  children={children} />;
        case 'word':
            return <WordElement {...{ attributes, element }}
                                    syllableComplexity={element.syllableComplexity}
                                children={children} />;
        case 'space':
            return <WordElement {...{ attributes, element }}
                                children={children} />;
        case 'loading':
            return <LoadingElement {...{ attributes, element }}  children={children} />;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

export default EditorElement
